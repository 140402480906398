import { Telas } from './telas';

// Empresa
export interface DadosEmpresa {
  nome: string;
  telefone: string;
  email: string;
  caminhoTermoPrivacidade: string;
}

// Login
export interface LoginDados {
  usuario: string;
  senha: string;
  bancoId: string;
  tokenDispositivo?: string | null;
  apelidoDispositivo?: string | null;
  identificacaoDispositivo?: string | null;
  plataformaLogin: TipoPlataformaLogin;
  UsuarioAutenticacao: TipoUsuarioAutenticacao;
}

export enum TipoPlataformaLogin {
  App = 0,
  Web = 1
}

export interface LoginResposta {
  id: number;
}

export interface DadosAjuda {
  nome: string;
  email: string;
  celular: string;
  cidade: string;
  estado: string;
  numeroIdentificador: string;
  codigoCliente: string;
  nomeCliente: string;
  texto: string;
  aplicativo: number;
  idioma: number;
}

// Funcionário
export enum TipoPlano {
  Gateway = -1,
  Basic = 0,
  Pro = 1,
  Ultimate = 2,
  Corporate = 3
}

export interface LoginFuncionarioDados {
  id: number;
  tokenDispositivo?: string | null;
  apelidoDispositivo?: string | null;
  identificacaoDispositivo?: string | null;
  plataformaLogin: TipoPlataformaLogin;
}

export enum TipoPerfil {
  Funcionario = 0,
  Gerente = 1,
  FuncionarioRestrito = 2
}

export enum TipoControleSolicitacoes {
  Aceitar = 0,
  MarcarVisto = 1
}

export enum NivelPermissao {
  PermiteAlteracoes = 0,
  NaoPermiteAlteracoes = 1,
  AcessoBloqueado = 2
}

export enum TipoHorario {
  Semanal = 0,
  EscalaCiclica = 1,
  EscalaMensal = 2
}

export enum TipoDiaSemana {
  Normal = 0,
  Extra = 1,
  Folga = 2
}

export enum TipoTelaIndicadorCustomizado {
  Funcionario = 0,
  Gerente = 1
}

export enum CorTextoIndicadorCustomizado {
  Verde = 0,
  Amarelo = 1,
  Vermelho = 2
}

export enum ColunaCalculoIndicadorCustomizado {
  Normais = 0,
  Faltas = 1,
  Extras = 2,
  Atrasos = 3,
  Adiantamentos = 4,
  DSR = 5,
  Not = 6,
  ExNot = 7,
  BCred = 8,
  BDeb = 9,
  BSaldo = 10
}

export enum TipoIndicadorCustomizado {
  Funcionario = 0,
  Gerente = 1
}

export enum ComparacaoIndicadorCustomizado {
  MaiorQue = 0,
  MenorQue = 1,
  Igual = 2
}

export interface ItemListaColunaCalculoIndicaor {
  nomeColuna: string;
  colunaCalculo: ColunaCalculoIndicadorCustomizado;
}

export type PropriedadeBatidas =
  | 'entrada1'
  | 'saida1'
  | 'entrada2'
  | 'saida2'
  | 'entrada3'
  | 'saida3'
  | 'entrada4'
  | 'saida4'
  | 'entrada5'
  | 'saida5';

export interface DadosHorarioItem {
  diaSemana: number;
  tipoDia: TipoDiaSemana;
  entrada1: string | null;
  saida1: string | null;
  entrada2: string | null;
  saida2: string | null;
  entrada3: string | null;
  saida3: string | null;
  entrada4: string | null;
  saida4: string | null;
  entrada5: string | null;
  saida5: string | null;
}

export interface DadosPeriodo {
  horario?: string;
  periodo: string;
}

export interface DadosHorario {
  tipo: TipoHorario;
  itens: Array<DadosHorarioItem>;
  tipoCompensacao: TipoCompensacao;
}

export enum TipoCompensacao {
  Diaria = 0,
  Semanal = 1,
  Mensal = 2
}

export interface DadosPerfilFuncionario {
  telasOcultar: Array<Telas>;
  colunasOcultar: Array<string>;
  tipoPerfil: TipoPerfil;
  controleSolicitacoes?: TipoControleSolicitacoes;
  restringirAjustePontos: boolean;
  permitirLigarFuncionariosAusentes: boolean;
}

export interface DadosFuncionario {
  id: number;
  nome: string;
  empresa: string;
  funcao: string;
  numeroIdentificador: string;
  email: string;
  celular: string;
  cidade: string;
  uf: string;
  cpf: string;
  perfil: TipoPerfil;
  dadosPerfilFuncionario: DadosPerfilFuncionario;
  nivelPermissao: NivelPermissao;
  assinaturaDigitalCartaoPontoHabilitada: boolean;
  podeIncluirPontoManual: boolean;
  podeIncluirAtividade: boolean;
  usaPonto10Batidas: boolean;
  usaExigenciaComprovanteEndereco: boolean;
  bancoHorasHabilitado: boolean;
  listaHorarios: Array<DadosPeriodo>;
  dadosHorario: DadosHorario;
  plano: TipoPlano;
  funcionalidades: Array<Funcionalidade>;
  configuracoesIncluirPontoOffline: {
    justificativaAutomatica: boolean;
    exigirCapturaFotoPonto: boolean;
    tipoCameraCapturaFotoPonto: TipoCameraFotoPonto;
    permitirInclusaoPontoOffline: boolean;
  };
  configuracoesEspeciaisAcesso: ConfiguracoesEspeciaisAcesso;
  configuracoesNotificacoesDados?: ConfiguracoesNotificacoesDados;
  receberEmailComprovantePonto: boolean;
  permiteInclusaoPontoDispositivosAutorizados: boolean;
  listaDispositivosAutorizadosIdentificacao: string[];
  loginEnderecoIp: string;
  loginDadosNavegador: string;
  permiteInclusaoPontoSemLocalizacao: boolean;
  possuiSolicitacaoAtualizacaoDadosCadastraisPendente: boolean;
  usaOutraLegislacao: boolean;
  ehClienteSecullum: boolean;
  dataAtualizacaoPortaria671?: string;
  aceitouTermosLgpd: boolean;
  desativarVerificacaoLocalFicticio: boolean;
  fusoMinutos: number;
}

export interface ConfiguracoesEspeciaisAcesso {
  tecpontoIntero: boolean;
}

export interface DadosListagemFuncionariosGerente {
  id: number;
  nome: string;
}

export interface IndicadorCustomizado {
  id: number;
  titulo: string;
  corTexto: CorTextoIndicadorCustomizado;
  colunaCalculo: ColunaCalculoIndicadorCustomizado;
  tipo: TipoIndicadorCustomizado;
  comparacao?: ComparacaoIndicadorCustomizado;
  valor?: string;
}

export interface IndicadorCustomizadoFuncionarioResultado {
  indicador: IndicadorCustomizado;
  nomeColunaIndicador: string;
  erros: Array<object>;
  dadosIndicador: IndicadoresDados;
}

export interface DadosFuncionarioIndicadorCustomizadoGerente {
  nome: string;
  celular: string;
  totalHorasFuncionario: string;
}

export interface IndicadorCustomizadoGerenteResultado {
  indicador: IndicadorCustomizado;
  nomeColunaIndicador: string;
  erros: Array<object>;
  funcionarios: Array<DadosFuncionarioIndicadorCustomizadoGerente>;
}

export interface ResumoDiarioResultado {
  titulo: string;
  erros: Array<object>;
  total: number;
  atual: number;
  funcionarios: Array<FuncionarioResumoDiario>;
}

export interface DadosAdicionaisFuncionario {
  endereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
  telefone: string;
  celular: string;
  email: string;
  rg: string;
  expedicaoRg?: Date;
  ssp: string;
  cpf: string;
  pai: string;
  mae: string;
  nascimento?: Date;
  nacionalidade: string;
  naturalidade: string;
  escolaridadeId: number | null;
  foto?: string;
  comprovanteEndereco?: string;
}

export interface AtualizarSenhaDados {
  senhaAtual: string;
  novaSenha: string;
  confirmarSenha: string;
}

// Justificativas
export interface Justificativa {
  id: number;
  nomeAbreviado: string;
  nomeCompleto: string;
  exigirFotoAtestado?: boolean;
}

// Batidas
export interface BatidasGeolocalizacao {
  latitude: number;
  longitude: number;
  precisao: number;
  endereco: string;
  dataHora: Date;
}

export interface BatidasItem {
  nome: string;
  valor: string;
  valorOriginal?: string;
  geolocalizacao?: BatidasGeolocalizacao;
  ehRepP?: boolean;
}

export interface ValoresItem {
  nome: string;
  nomeOriginal: string;
  valor: string;
}

export enum TipoSituacao {
  DiaCompleto = 0,
  DiaIncompleto = 1,
  DiaComFaltas = 2
}

export interface BatidasDados {
  id: number;
  data: Date;
  funcionarioNome: string;
  batidas: Array<BatidasItem>;
  valores: Array<ValoresItem>;
  saldo: string;
  situacao: TipoSituacao;
  registroPendente: boolean;
  existePeriodoEncerrado: boolean;
}

export interface CartaoPontoDados {
  dataInicio: Date;
  dataFinal: Date;
  funcionarioId?: number;
}

export interface IndicadorCustomizadoFiltro {
  dataInicio: Date;
  dataFinal: Date;
  indicadorId?: number;
}

export interface PontoDiarioDados {
  data: Date;
}

export interface OpcoesCartaoPonto {
  funcionarioId: number;
  exibirTotaisNoRodape: boolean;
  exibirMiniaturaDoHorario: boolean;
  exibirEventos: boolean;
  modoPaisagem: boolean;
  exibirTermosMTB: boolean;
  colunasSelecionadasImpressao: Array<string>;
  exibirJustificativas: boolean;
  justificativasPreenchidasUsuario: boolean;
  exibirLegendasJustificativas: boolean;
  exibirAtividades: boolean;
}

export interface BatidasResposta {
  lista: Array<BatidasDados>;
  justificativas: Array<Justificativa>;
  totais: Array<ValoresItem>;
  opcoesCartaoPonto: OpcoesCartaoPonto;
}

// Incluir Ponto
export interface DadosIncluirPonto {
  dataHora?: Date;
  fusoHorarioMinutos?: number | null;
  justificativa: string | null;
  latitude: number | null;
  longitude: number | null;
  precisao: number | null;
  endereco: string | null;
  foto: string | null;
  marcacaoOffline: boolean;
  viaCentralWeb: boolean;
  horaFoiModificada: boolean;
  identificacaoDispositivo: string | null;
  foraDoPerimetro: boolean;
  utilizaLocalizacaoFicticia: boolean;
  atividadeId: number | null;
}

export interface InclusaoPontoDados {
  horaEmMinutos: number;
  justificativa: string | null;
}

export interface QualidadeVidaTrabalho {
  habilitado: boolean;
  pergunta: string;
  respostaExcelente: string;
  respostaExcelenteDescricao: string;
  respostaMuitoBem: string;
  respostaMuitoBemDescricao: string;
  respostaBem: string;
  respostaBemDescricao: string;
  respostaNaoEstouBem: string;
  respostaNaoEstouBemDescricao: string;
  respostaMal: string;
  respostaMalDescricao: string;
  exibirPesquisa: TipoExibirPesquisa;
}

export enum TipoRespostaQualidadeVidaTrabalho {
  Excelente = 0,
  MuitoBem = 1,
  Bem = 2,
  NaoEstouBem = 3,
  Mal = 4,
  NaoRespondeu = 5
}

export interface QualidadeVidaTrabalhoResposta {
  respostaPesquisa: TipoRespostaQualidadeVidaTrabalho;
}

export enum TipoExibirPesquisa {
  TodasBatidas = 0,
  PrimeiraBatidaDia = 1
}

export enum TipoInclusao {
  IncluirPonto = 0,
  IncluirAtividade = 1
}

export interface Atividade {
  id: number;
  descricao: string;
  descricaoAbreviada: string;
}

export interface InclusaoPontoResposta {
  horaServidor: Date;
  origemHorario: string;
  justificativaAutomatica: boolean;
  funcionarioAfastado: boolean;
  exigirCapturaFotoPonto: boolean;
  tipoCameraCapturaFotoPonto: TipoCameraFotoPonto;
  reconhecerFace: boolean;
  somentePerimetrosAutorizados: boolean;
  perimetrosAutorizados: PerimetroAutorizado[];
  qualidadeVidaTrabalho: QualidadeVidaTrabalho;
  atividades: Atividade[];
}

export enum TipoCameraFotoPonto {
  LivreEscolhaFuncionario = 0,
  SomenteCameraFrontal = 1,
  SomenteCameraTraseira = 2
}

export interface RegistroPontoPendencia {
  id: number;
  dataHora: Date;
  latitude: number;
  longitude: number;
  precisao: number;
  endereco: string;
  status: RegistroPontoPendenciaStatus;
  motivoRejeicao: string;
  foraDoPerimetro: boolean;
  atividadeId: number | null;
  atividadeDescricaoAbreviada: string | null;
  fonteDadosId: number | null;
}

export enum RegistroPontoPendenciaStatus {
  Pendente = 0,
  Aceito = 1,
  Rejeitado = 2
}

// Solicitações
export enum TipoSolicitacao {
  AlteracaoDePonto = 0,
  AjusteParcial = 1,
  JustificativaDiaInteiro = 2,
  Periodo1 = 3,
  Periodo2 = 4,
  Periodo3 = 5,
  AtualizacaoDadosCadastrais = 6,
  ForaDoPerimetro = 7,
  ExclusaoDeConta = 8,
  UtilizaLocalizacaoFicticia = 11,
  Periodo4 = 12,
  Periodo5 = 13,
  HoraFoiModificada = 14,
  Afastamento = 15
}

export enum TipoAusencia {
  DiaEspecifico = 0,
  PeriodoAfastamento = 1
}

export enum TipoAlteracaoFonteDados {
  Troca = 0,
  ApagarFonteDados = 1,
  IncluirFonteDadosDescarte = 2,
  IncluirFonteDadosInclusaoManual = 3,
  EditarFonteDadosInclusaoManual = 4,
  MarcarVisto = 5,
  DesmarcarVisto = 6
}

export enum TipoAlteracaoAssinaturaDigitalCartaoPonto {
  Aprovar = 0,
  Rejeitar = 1
}

export interface SolicitacaoDados {
  data: Date;
  justificativaId: number | null;
  entrada1: string;
  saida1: string;
  entrada2: string;
  saida2: string;
  entrada3: string;
  saida3: string;
  entrada4: string;
  saida4: string;
  entrada5: string;
  saida5: string;
  tipo: TipoSolicitacao;
  observacoes: string;
  foto: string | null;
  temFoto: boolean;
  funcionarioId?: number;
  filtro1Id?: number | null;
  filtro2Id?: number | null;
  periculosidade?: string;
  registroPendente: boolean;
  existePeriodoEncerrado: boolean;
  tipoAusencia?: TipoAusencia;
  dataInicioAfastamento?: Date;
  dataFimAfastamento?: Date;
}

export enum SolicitacoesFiltrosStatus {
  Todos = 0,
  Processados = 1,
  Pendentes = 2
}

export interface SolicitacoesFiltros {
  dataInicio: Date | null;
  dataFinal: Date | null;
  status: SolicitacoesFiltrosStatus;
  perfil: TipoPerfil;
  solicitacaoId?: number;
}

export enum SolicitacoesStatus {
  Pendente = 0,
  Aceita = 1,
  Descartada = 2,
  Visto = 3
}

export interface SolicitacaoAlteracaoFonteDados {
  tipo: TipoAlteracaoFonteDados;
  coluna: String;
  colunaTroca: String;
  motivo: String | null;
}

export interface SolicitacoesDados {
  id: number;
  data: Date;
  dataFim?: Date;
  tipo: string;
  tipoSolicitacao: TipoSolicitacao;
  motivo: string;
  status: SolicitacoesStatus;
  observacao: string;
  dataSolicitacao?: Date;
  batidas: Array<{
    nome: string;
    valor: string;
    valorOriginal?: string;
    coluna?: string;
  }>;
  motivoRejeicao?: string;
  funcionarioNome: string;
  dados: string;
  alteracoesFonteDados: Array<SolicitacaoAlteracaoFonteDados>;
  solicitacaoFotoId?: number;
  filtro1Descricao?: string;
  filtro2Descricao?: string;
  periculosidade?: string;
  versao?: string;
}

export interface SolicitacaoFoto {
  foto: string;
}

export interface SolicitacaoCarregarDados {
  data: Date;
  funcionarioId?: number;
}

// Notificações
export enum ConfiguracoesNotificacoesTipo {
  NotificarSempre = 0,
  NaoNotificar = 1,
  QuandoEsquecer = 2
}

export interface ConfiguracoesNotificacoesDadosServidor {
  notificacaoEntrada1: ConfiguracoesNotificacoesTipo;
  notificacaoSaida1: ConfiguracoesNotificacoesTipo;
  notificacaoEntrada2: ConfiguracoesNotificacoesTipo;
  notificacaoSaida2: ConfiguracoesNotificacoesTipo;
  notificacaoEntrada3: ConfiguracoesNotificacoesTipo;
  notificacaoSaida3: ConfiguracoesNotificacoesTipo;
  notificacaoEntrada4: ConfiguracoesNotificacoesTipo;
  notificacaoSaida4: ConfiguracoesNotificacoesTipo;
  notificacaoEntrada5: ConfiguracoesNotificacoesTipo;
  notificacaoSaida5: ConfiguracoesNotificacoesTipo;
}

export interface ConfiguracoesNotificacoesDados {
  entrada1: ConfiguracoesNotificacoesTipo;
  saida1: ConfiguracoesNotificacoesTipo;
  entrada2: ConfiguracoesNotificacoesTipo;
  saida2: ConfiguracoesNotificacoesTipo;
  entrada3: ConfiguracoesNotificacoesTipo;
  saida3: ConfiguracoesNotificacoesTipo;
  entrada4: ConfiguracoesNotificacoesTipo;
  saida4: ConfiguracoesNotificacoesTipo;
  entrada5: ConfiguracoesNotificacoesTipo;
  saida5: ConfiguracoesNotificacoesTipo;
}

// Indicadores
export interface IndicadoresDados {
  [tipoVisualizacao: string]: {
    valores: Array<{ x: string; y: number }>;
  };
}

export interface TotalSolicitacoesPendentes {
  quantidade: number;
}

export interface FuncionarioResumoDiario {
  nome: string;
  celular: string;
}

export interface FiltroNotificacoes {
  dataInicio: Date;
  dataFinal: Date;
}

export enum TipoNotificacao {
  SolicitacaoDescartada = 0,
  EsqueceuBaterPonto = 1,
  RegistroPontoRejeitado = 2,
  NovaAssinaturaDigitalCartaoPonto = 3,
  NovoArquivoDisponivel = 7,
  AvisoRevisarCartaoPonto = 8
}

export interface NotificacaoDados {
  id: number;
  dataHora: Date;
  mensagem: string;
  detalheMensagem: string;
  tipo: TipoNotificacao;
  visualizada: boolean;
  solicitacaoId: number | undefined;
  assinaturaDigitalCartaoPontoId: number | undefined;
  repositorioArquivoId: number | undefined;
}

export interface DefaultList {
  id: number;
  descricao: string;
}

// Assinatura Eletrônica de Cartão Ponto
export interface AssinaturaDigitalCartaoPontoDados {
  id: number;
  assinaturaDigitalCartaoPontoId: number;
  dataInclusao: Date;
  dataInicio: Date;
  dataFim: Date;
  dataResposta?: Date;
  descricao: string;
  motivo: string;
  senha: string;
  estado: AssinaturaDigitalCartaoPontoStatus;
}

export enum AssinaturaDigitalCartaoPontoStatus {
  NaoRespondido = 0,
  Aprovado = 1,
  Rejeitado = 2
}

export enum AssinaturaDigitalCartaoPontoFiltrosStatus {
  Todos = 0,
  Processados = 1,
  Pendentes = 2
}

export interface AssinaturaDigitalCartaoPontoFiltros {
  dataInicio: Date;
  dataFinal: Date;
  status: AssinaturaDigitalCartaoPontoFiltrosStatus;
  solicitacaoId?: number;
}

export interface PerimetroAutorizado {
  circulo: boolean;
  radius?: number;
  coordenadas: Array<Coordenadas>;
}

export interface Coordenadas {
  lat: number;
  lng: number;
  accuracy: number | null | undefined;
}

export enum TipoUsuarioAutenticacao {
  Folha = 0,
  Identificador = 1
}

export enum ArquivosFiltroData {
  PorPeriodo = 0,
  Todos = 1
}

export interface ArquivosFiltros {
  dataInicio: Date;
  dataFinal: Date;
  funcionarioId: number;
  filtrosData: ArquivosFiltroData;
}

export interface ArquivosDados {
  id: number;
  nomeArquivo: string;
  tipoArquivo: string;
  dataArquivo: Date;
  validadeArquivo: Date | null;
  descricao: string;
  filtroPeriodo: ArquivosFiltroData;
  vencido: boolean;
  assinaturas: Array<{
    status: ArquivoDadosStatus;
    motivo: string | null;
    dataAssinatura: Date;
  }>;
  solicitarAssinaturaFuncionario: boolean;
}

export interface RepositorioArquivoAssinaturasDados {
  status: ArquivoDadosStatus | null;
  motivo: string | null;
  dataResposta: Date | null;
}

export interface RepositorioArquivoAssinaturasDadosAprovar {
  repositorioArquivoId: number | null;
  senha: string | null;
  endereco: string | null;
  latitude: number | undefined;
  longitude: number | undefined;
  precisao: number | null | undefined;
  repositorioArquivosIds: Array<number> | null;
}

export interface RepositorioArquivoAssinaturasDadosRejeitar {
  repositorioArquivoId?: number | null;
  motivo: string | null;
  repositorioArquivosIds?: Array<number> | null;
}

export enum ArquivoDadosStatus {
  Aceita = 0,
  Descartada = 1
}

export interface DadosRecuperacaoSenha {
  tipoUsuarioAutenticacao: number;
  usuario: string;
  codigo: string;
  novaSenha: string;
  confirmarSenha: string;
  bancoId: string;
}

export interface DadosConfirmacaoCodigo {
  tipoUsuarioAutenticacao: number;
  usuario: string;
  codigo: string;
  bancoId: string;
}

export interface DadosConfirmacaoEmail {
  tipoUsuarioAutenticacao: number;
  usuario: string;
  email: string;
  bancoId: string;
}

export enum Funcionalidade {
  GestaoArquivos = 0
}
